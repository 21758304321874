@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@800&display=swap');


h1 {
  font-family: 'Nunito', sans-serif;
  color: #2c2c2c;
}
.single__product-content {
  padding-left: 20px;
}

.static-bar {
  display: flex;
  justify-content: space-between;
  margin-left: -10px;
  background-color: #ffffff;
  padding: 10px;
  padding-left: 30px;
  padding-right: 50px;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
}
.barra-bottom-p{
  margin-bottom: 1px;
  
}

.td-producto{
  text-transform: capitalize;
}

.td-local{
  font-weight: 600;
  text-transform: capitalize;
}

.div-barra-bottom-button{
  display: flex;
  margin-top: 5px;
  padding: 5px;
  justify-content: center;
  align-items: center;
  height: 100%; 
  background-color: #22d251;
  font-size: 18px;
  font-weight: 600;
  color: white ;
  border-radius: 15px;
  box-shadow: rgba(0, 114, 23, 0.6) 0px 3px 8px;
  
}

.div-barra-bottom-text{
  line-height: 130%;
}

.barra-bottom-precio{
  font-weight: bolder ;
  font-size: 30px;
  font-family: 'Roboto', sans-serif;

}

.contenedor-categorias {
  max-height: 120px;
  margin-top: 13%;
  margin-bottom: 30px;
  display: flex;
  overflow-x: auto;
  padding: 5px;
  text-transform: capitalize;
}

.contenedor-producto {
  border-bottom: 1px solid #9B9B9B;
  display: flex !important;
  justify-content: space-between !important;

}


.container-search {
  position: relative;
  ;
}


.ri-search-line {
  font-size: x-large;
  position: absolute;
  top: 13px;
  margin-left: 5px;
}


.titulo-producto {
  display: flex;
  gap: 0.6rem;
  vertical-align: middle;
  line-height: 25px;
}

.texto-producto {
  vertical-align: middle;
  display: flex;
  flex-direction: column;
}

.categoria-producto {
  font-size: 13px;
  font-weight: 600;
  color: #961c1c;
  margin-top: 3px;
}

.nombre-producto {
  font-size: 18px;
  font-weight: 700;
  text-transform: capitalize;
  margin-top: 3px;
}

.descripcion-producto {
  font-size: 12px;
  font-weight: 600;
  max-width: 100%;
}

.descripcion-producto::first-letter{
  text-transform: uppercase;
}


.container-subs {
  display: flex !important;
  justify-content: space-between !important;
  vertical-align: middle;
  line-height: 10px;
}

.productos-container-general{
  margin-bottom: 35%;
}



.nombre-subs {
  font-size: 14px;
  font-weight: 600;
  margin-right: 20px;
  vertical-align: bottom;
  text-transform: capitalize;

}

.contenedor-imagen img {
  max-width: 100px;
  border-radius: 5px;
}

.contenedor-imagen {
  display: flex;
  justify-items: center;
  align-items: center;
  max-width: fit-content;
}

.precio-producto {
  font-size: larger;
  margin-top: -10px;
  margin-bottom: -1px;
  font-weight: 800;
}

.search-term{
  text-transform: capitalize;
}

.navegacion {

  align-items: center;
  min-width: 100px;
  background-clip: padding-box;
  background-color: rgb(234, 227, 227);
  border: 1px solid transparent;
  border-radius: .25rem;
  box-shadow: rgba(0, 0, 0, 0.2) 0 1px 3px 0;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  justify-content: center;
  margin: 0;
  min-height: 3rem;
  padding: 2px;
  height: 70px;
  margin: 2px;
}

.navegacion-selected {

  align-items: center;
  min-width: 100px;
  background-clip: padding-box;
  background-color: #17526b;
  transition: background-color 1s ease 0s;
  border: 1px solid transparent;
  border-radius: .25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  margin: 0;
  min-height: 2.5rem;
  padding: 2px;
  height: 70px;
  margin: 2px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}



.logo-local {
  max-width: 170px;
  border-radius: 15px;
}

.input-busqueda {
  width: 100%;
  height: 50px;
  font-size: medium;
  border: 1px solid;
}

.container-subs {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

}

.info-local {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 200px;
}

.product__price {
  display: flex;
  align-items: center;
  text-transform: capitalize;
  column-gap: 1rem;
  margin-bottom: 0;
  font-size: 40px;

}

.product__price span {
  font-size: 2rem;
}

.category {
  font-weight: 600;
  color: #212245;
  display: flex;
  align-items: center;
  column-gap: 1rem;
  margin-top: 10px;
}

.category span {
  padding: 5px 10px;
  background: #fde4e4;
  color: #212245;
  border-radius: 5px;
}

.tabs {
  border-bottom: 1px solid #fde4e4;
}

.tabs h6 {
  cursor: pointer;
}

.tab__content {
  padding: 30px 0px;
}

.tab__content p {
  line-height: 30px;
  color: #212245b6;
}

.tab__active {
  color: #df2020;
}

.form {
  width: 60%;
  margin: auto;
  background: #fde4e4;
  padding: 20px;
  border-radius: 5px;
}

.form__group {
  margin-bottom: 30px;
  margin-top: 10px;
}

.input-prefix {
    display: flex;
    align-items: center;
}

.prefix {
    padding: 5px;
    border-right: none;
    border-radius: 4px 0 0 4px;
}

input[type="tel"] {
    border: 1px solid #ccc;
    border-left: none;
    padding: 5px;
    border-radius: 0 4px 4px 0;
    width: 100%;
}

.form__group input,
.form__group textarea {
  width: 100%;
  background: transparent;
  padding: 7px 20px;
  border: none;
  border-bottom: 1px solid #2122454c;
}

.form__group input:focus,
.form__group textarea:focus {
  outline: none;
}

.user__name {
  font-weight: 600;
  color: #212245;
}

.enlaces-local {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  font-weight: 600;
}

.ubicacion-local {
  font-size: 20px;
  color: #212245;
}

.descripcion-subs {
  color: #787878;
  font-weight: 600;
}


.info-div {
  display: flex;
  flex-direction: row;
  margin-bottom: 3px;
  text-indent: 8px;
  font-size: 0.9rem;
  vertical-align: middle;
}

.adicionalPorTaper {
  font-size: 18px;
  font-weight: 600;
  text-decoration: underline;
}





.img__item {
  cursor: pointer;
}

.related__Product-title {
  font-size: 1.4rem;
}

@media only screen and (max-width: 768px) {
  .product__title {
    font-size: 1.2rem;
  }

  .img-carta{
    width: 450px;
  }

  .info-local {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 150px;
    margin-right: 10px;
  }


  .descripcion-subs {
    font-size: 11px;
    max-width: 57%;
    
  }

  .ri-search-line {
    font-size: x-large;
    position: absolute;
    top: 13px;
    margin-left: 5px;
  }

  .container-search {
    position: relative;
    ;
  }

  .input-busqueda {
    margin-bottom: 40px;
  }

  .product__price,
  .product__price span {
    font-size: 2rem;
  }

  .category,
  .category span {
    font-size: 0.8rem !important;
  }

  .tabs {
    margin-top: 30px;
  }

  .tabs h6 {
    font-size: 0.9rem;
  }

  .tab__content p,
  .user__name,
  .feedback__text {
    font-size: 0.8rem;
  }

  .user__email {
    font-size: 0.7rem;
  }

  .form {
    width: 100% !important;
    margin-top: 50px;
  }

  .form__group input::placeholder,
  .form__group textarea::placeholder {
    font-size: 0.8rem;
  }

  .related__Product-title {
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 576px) {
  .product__images {
    display: flex;
    flex-direction: row;
  }

  .product__main-img {
    margin-bottom: 20px;
  }

  .img-carta{
    width: 350px;
  }
}