@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@500&display=swap');
.horario-local{
  font-size: 20px;
  font-weight: 600;

}

.contenedor-mapa {
  text-align: center;
}

img {
  max-width: 100%;
  height: auto;
}

.icono {
  font-size: 40px;
}

.nombre {
  display: flex;
  justify-content: space-between;
  margin-top: -20px;
  font-family: 'Kanit', sans-serif;
  text-transform: capitalize ;
  font-size: 30px;
}

.ubicacion {
  text-transform: capitalize;
  font-size: small;
  margin-top: 10px;
  color: #4c4c4c;

}

.logo-local{
  width: 50% !important;
}

.intro {
  display: flex;
  justify-content: center;
  gap: 40px;
}


.profile-card {
  height: auto;
  text-align: center;
  margin: 20px auto;
  box-shadow: 0px 0px 18px #ccc;
}


.profile-card .profile-body {
  background-color: #fff;
  padding: 20px 40px 40px 40px;
  border-radius: 20px;
}

.profile-card .profile-body .author-img {
  margin-top: -80px;
  margin-left: -70%;
  margin-bottom: 20px;
}

.profile-card .profile-body .author-img img {
  width: 170px;
  height: 170px;
  border-radius: 50%;
  padding: 5px;
  background-color: var(--white);
}



.profile-header {
  height: 300px;
  overflow: hidden;
  margin: 10px;
  position: relative;
  border-radius: 20px;
  object-fit: contain;
}

.banner {
  position: absolute;
  object-fit: fill;
  left: -100%;
  right: -100%;
  top: -100%;
  bottom: -100%;
  margin: auto;
  min-height: 100%;
  max-width: 100%;
  z-index: -1;
  border-radius: 20px;
  
}

.profile-card .profile-body .intro {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6;
  margin: 20px 0px 30px 0px;

}

.social-icon ul {
  list-style-type: none;
}

.social-icon ul li {
  display: inline-block;
}

.social-icon ul li a {
  margin-right: 5px;
  width: 35px;
  height: 35px;
  border: 1px solid #ddd;
  display: block;
  border-radius: 50%;
  transition: all 0.5s ease-out;
}

.social-icon ul {
  margin: 0;
  padding: 0;
}

.social-icon ul li a:hover {
  background-color: var(--dark);
  border: 1px solid var(--dark);
}

.social-icon ul li a i {
  line-height: 35px;
  color: #666;
}

.social-icon ul li a i:hover,
.social-icon ul li a:hover i {

  color: var(--white);
}

.profile-body {}

@media only screen and (max-width: 768px) {
  .profile-header {
    height: 190px;
    border-radius: 20px;
  }

 

  .contenedor-mapa {
    max-width: 600px; /* Establece el ancho máximo del contenedor */
    margin: 0 auto; /* Centra el contenedor en pantallas más grandes */
  }

  
}