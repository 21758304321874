@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.product-summary-table {
  width: 100%;
  margin-top: 20px;
}

.product-summary-table table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.product-summary-table th,
.product-summary-table td {
  padding: 1px;
  border: 1px solid #ddd;
  text-align: center;
  font-size: 13px;
}

.mapa-checkout{
  width: 400px;
  height: 400px;
}


.direccion-pedido-checkout{
  font-size: small;
  line-height: 100%;
  font-family: "Poppins", serif;
  font-weight: 600;
}

.p-direccion-checkout{
  font-weight: 500;
  font-size: 14px;
  color: #626262;
}

.mensaje-ubicacion-cliente {
  position: fixed; /* o absolute dependiendo de tu necesidad */
  top: 15%; /* distancia desde arriba */
  left: 50%; /* lo posiciona al centro */
  transform: translateX(-50%); /* centra el elemento horizontalmente */
  font-family: "Poppins", serif;
  font-weight: 500;
  padding: 12px 10px;
  max-width: 80%; /* para que no ocupe todo el ancho en pantallas grandes */
  width: 100%;
  font-size: 22px;
  background-color: #373737ce;
  color: #f4f4f4;
  border: 1px solid #ffeeba;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* sombra sutil */
  z-index: 1000; /* asegura que esté por encima del mapa */
}

.icon-alert{
  font-size: 40px;
  width: 100%;
  color: yellow;
  background-color: #004690;
  border-radius: 5px;
}

.icon-check{
  font-size: 20px;
  
}

.product-summary-table th {
  background-color: #f4f4f4;
  font-weight: bold;
  color: #333;
}

.product-summary-table td {
  font-size: 13px;
  color: #555;
  line-height: 105%;
}

.product-summary-table td:first-child {
  width: 8%;
}

.product-summary-table td:nth-child(2) {
  text-align: left;
}

.product-summary-table td:last-child {
  font-weight: bold;
  color: #007bff;
  white-space: nowrap;
  font-size: 14px;
}

.payment-buttons {
  display: flex;
  gap: 10px;
  /* Espacio entre los botones */
  justify-content: center;
  margin-bottom: 10px;
  margin-top: 15px;
}


.text-resumen {
  margin-top: 20px;
}

.payment-button {
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  background-color: transparent;
  /* Fondo transparente */
  border: 2px solid #ccc;
  /* Borde gris claro */
  border-radius: 8px;
  /* Borde redondeado */
  padding: 10px;
  cursor: pointer;
  transition: transform 0.2s, border-color 0.2s;
}

.payment-button img {
  width: 50px;
  /* Tamaño de la imagen */
  height: 50px;
}

.payment-button span {
  font-size: 12px;
  /* Tamaño de la fuente para el texto */
  color: #585858;
}

.payment-button:hover {
  transform: scale(1.1);
  /* Efecto de agrandamiento al pasar el ratón */
  border-color: #007bff;
  /* Cambiar el color del borde al pasar el ratón */
}

.payment-button.active {
  border-color: #007bff;
  /* Borde azul para el botón seleccionado */
}


.checkout__bill {
  background: #fde4e4;
  padding: 30px;
}

.checkout__total {
  border-top: 1px solid #ddd;
  padding-top: 30px;
}

.product-summary {
  display: flex;
  justify-content: space-between;
  /* Distribuye los elementos en el ancho del contenedor */
  align-items: center;
  /* Alinea verticalmente los elementos */

  border-bottom: 1px solid #ccc;
  /* Línea divisoria entre productos */
  margin-bottom: 10px;
  /* Espacio inferior entre productos */
}

.product-quantity {
  width: 10%;
  /* Ajusta el ancho de la cantidad */
  font-weight: bold;
  /* Hace que la cantidad resalte */
}

.product-name {
  width: 60%;
  /* Ajusta el ancho del nombre */
  text-align: left;
  /* Alineación a la izquierda */
  font-size: 14px;
}

.product-price {
  width: 30%;
  /* Ajusta el ancho del precio */
  text-align: right;
  /* Alineación a la derecha */
  font-weight: bold;
  /* Hace que el precio resalte */
  color: #007bff;
  /* Color del precio */
}



.container-obtener-ubicacion {
  display: flex;
  flex-direction: column;
  height: 100vh;
  /* Contenedor principal ocupa toda la pantalla */
}

.error {
  color: red;
  font-size: 0.9rem;
  margin-top: 5px;
}

.container-mensaje-ubicacion {
  margin: 5%;
}

.button-entendido-ubicacion {
  background-color: #009205;
  border: 0px;
  color: white;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 10px;
  text-align: center;
  font-weight: 600;
  border-radius: 15px;
  margin-top: 15%;
}

.div-texto-mueve-mapa {
  position: absolute;
  top: 0;
}

.mapa {
  position: relative;
  height: 90%;
  /* El mapa ocupa el 70% de la altura de la pantalla */
  width: 100%;
  /* El mapa ocupa el 100% del ancho */
}

.container-mensaje-ubicacion {
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* Espacio entre los bloques */
  padding: 20px;
  background-color: #f7f7f7;
  /* Color de fondo suave */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Sombra ligera */
  width: 100%;
  max-width: 600px;
  /* Máximo ancho */
  margin: 0 auto;
  /* Centrar en la página */
}

.container-mensaje-ubicacion h2 {
  font-size: 24px;
  margin-bottom: 10px;
  /* Espacio debajo del título */
  color: #333;
  text-align: center;
}

.container-mensaje-ubicacion h4 {
  font-size: 18px;
  margin-bottom: 5px;
  /* Espacio debajo del subtítulo */
  color: #333;
}

.container-mensaje-ubicacion p {
  font-size: 13px;
  margin-bottom: 10px;
  /* Espacio debajo del párrafo */
  color: #666;
  /* Color gris suave */
}

.errorCampo {
  color: white !important;
  padding: 2px;
  border-radius: 5px;
  font-size: 1rem !important;
  font-weight: bold;
  margin-top: 5px;
  background-color: red;
}

.ubication-div {
  display: flex;
  justify-content: space-between;
}

.input-direccion,
.input-referencia,
.input-telefono {
  width: 100%;
  padding: 10px;
  font-size: 15px;

  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease;
  color: #494949;
}

.input-direccion::placeholder,
.input-telefono::placeholder,
.input-referencia::placeholder {
  color: #888;
  font-style: italic;
  font-size: 15px;
}

.input-direccion:focus,
.input-referencia:focus {
  border-color: #007bff;
  border: 2px solid #007bff;
}


.button-guardar-direccion {
  background-color: #28a745;
  /* Color de fondo verde */
  color: #fff;
  /* Color del texto en blanco */
  border: none;
  /* Sin borde */
  border-radius: 5px;
  /* Bordes redondeados */
  padding: 10px 20px;
  /* Espaciado interno */
  font-size: 16px;
  /* Tamaño de fuente */
  cursor: pointer;
  /* Cambiar cursor a puntero */
  transition: background-color 0.3s ease, transform 0.2s ease;
  /* Transiciones suaves para color de fondo y transformación */
}

.button-guardar-direccion:hover {
  background-color: #218838;
  /* Color de fondo más oscuro al pasar el mouse */
  transform: scale(1.05);
  /* Efecto de agrandamiento al pasar el mouse */
}

.button-guardar-direccion:disabled {
  background-color: #c0c0c0;
  /* Color gris para botón deshabilitado */
  cursor: not-allowed;
  /* Cambiar cursor a no permitido */
}


@media (min-width: 768px) {
  .container-mensaje-ubicacion {
    gap: 30px;
  }

  .container-mensaje-ubicacion div {
    flex: 1;
  }
}


.button-send {
  background-color: #28a745;
  /* Verde estilo éxito */
  color: white;
  border: none;
  border-radius: 5px;
  padding: 12px 24px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Sombra ligera */
  width: 100%;
  /* Se ajusta al ancho del contenedor */
}

.button-send:hover {
  background-color: #218838;
  /* Color más oscuro al pasar el mouse */
  transform: translateY(-2px);
  /* Efecto de elevación */
}

.button-send:active {
  background-color: #1e7e34;
  /* Aún más oscuro cuando está activo */
  transform: translateY(0);
  /* Quita la elevación al hacer clic */
}

.button-send:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(72, 180, 97, 0.5);
  /* Resaltar en foco */
}

.div-buttons-ubi {
  display: flex;
  justify-content: center;
}


.button-borrar-ubi {
  color: white;
  border: 0px;
  background-color: rgb(179, 40, 40);
  font-weight: 600;
  width: 100%;
  padding: 5px;
  border-radius: 15px;
}


.button-ubicacion {
  position: fixed; /* o absolute si prefieres que se mantenga dentro del contenedor padre */
  bottom: 20px; /* distancia desde abajo */
  right: 20px; /* distancia desde la derecha */
  background-color: green;
  border-radius: 10px;
  color: white;
  font-weight: bold;
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  /* Opcional: para que el botón no ocupe todo el ancho */
  width: auto;
  min-width: 200px; /* o el ancho que prefieras */
  /* Opcional: añadir sombra para que se vea más flotante */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.obtener-ubicacion-text {
  padding-top: 2px;
}


.logo-pago {
  width: 23px;
}

.img-pago {
  width: 70px;
}

.wrapper-check {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 5px;
  padding: 20px 15px;
}

.wrapper-check .option {
  background: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 10px;
  border-radius: 5px;
  cursor: pointer;
  padding: 0 10px;
  border: 2px solid lightgrey;
  transition: all 0.3s ease;
}

.wrapper-check .option .dot {
  height: 20px;
  width: 20px;
  background: #d9d9d9;
  border-radius: 50%;
  position: relative;
}

.wrapper-check .option .dot::before {
  position: absolute;
  content: "";
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  background: #0069d9;
  border-radius: 50%;
  opacity: 0;
  transform: scale(1.5);
  transition: all 0.3s ease;
}

input[type="radio"] {
  display: none;
}

#option-1:checked:checked~.option-1,
#option-2:checked:checked~.option-2,
#option-3:checked:checked~.option-3 {
  border-color: #0069d9;
  background: #0069d9;
}

#option-1:checked:checked~.option-1 .dot,
#option-2:checked:checked~.option-2 .dot,
#option-3:checked:checked~.option-3 .dot {
  background: #fff;
}

#option-1:checked:checked~.option-1 .dot::before,
#option-2:checked:checked~.option-2 .dot::before,
#option-3:checked:checked~.option-3 .dot::before {
  opacity: 1;
  transform: scale(1);
}

.wrapper .option span {
  font-size: 20px;
  color: #808080;
}

#option-1:checked:checked~.option-1 span,
#option-2:checked:checked~.option-2 span,
#option-3:checked:checked~.option-3 span {
  color: #fff;
}

@media only screen and (max-width: 768px) {
  .checkout__bill h6 {
    font-size: 0.9rem;
  }

  .checkout__bill {
    margin-top: 40px;
  }
}


.checkout-summary-card {
  background-color: #d7d7d7;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  padding: 15px;
  margin-top: 20px;
}

.checkout-card-body {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 0 5px 0 5px;
}



.checkout-summary-item {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  color: #555;
}

.checkout-summary-total {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  font-weight: bold;
  padding-top: 5px;
  border-top: 1px solid #ccc;
}