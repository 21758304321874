@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');



.contenedor {
    background-color: darkslateblue;
    padding: 5px;
    color: aliceblue;
    display: flex;
    justify-content: space-between;
    border-radius: 5px;
    background: linear-gradient(356deg, rgba(227, 226, 226, 0.684) 0%, rgba(196, 196, 199, 1) 18%, rgba(198, 198, 201, 0) 53%, rgba(135, 135, 135, 0.16290266106442575) 69%);
    cursor: pointer;
    max-height: 120px;
    gap: 10px;
    margin-bottom: 15px;



}

.contenedor-cerrado {
    background-color: darkslateblue;
    padding: 5px;
    color: aliceblue;
    display: flex;
    justify-content: space-between;
    border-radius: 5px;
    background: rgb(203, 203, 203);
    background: linear-gradient(356deg, rgba(203, 203, 203, 0.6839110644257703) 0%, rgba(196, 196, 199, 1) 18%, rgba(198, 198, 201, 0) 53%, rgba(135, 135, 135, 0.16290266106442575) 69%);
    cursor: pointer;
    max-height: 120px;
    opacity: 0.45;
    margin-bottom: 15px;


}


.tags-div {
    display: flex;
    flex-wrap: wrap;
    /* Agregar la propiedad flex-wrap */
    gap: 2px;
    margin-top: -15px;
}

.tag {
    margin-right: 1px;
    margin-bottom: 1px;
    background-color: rgba(255, 0, 0, 0.957);
    padding-left: 3px;
    padding-right: 3px;
    padding-top: 1px;
    padding-bottom: 1px;
    font-size: 10.5px;
    font-weight: 600;
    border-radius: 5px;
    text-transform: capitalize;
    color: #e2e2e2;
}

.horario-card {
    color: #414141;
    font-weight: 600;
}



.logoLocal {
    max-height: 110px;
    width: auto;
    border-radius: 7px;
}

.titulo {
    color: #414141;
    font-size: 15px;
    margin-bottom: -6px;
    text-transform: capitalize;
}

.texto-local {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 70%;
    margin-bottom: 10px;
}

.tiempo-icon {
    color: #414141;
}

.tiempo-div {
    display: flex;
    gap: 2px;
    margin-top: -8px;
}

.tiempo {
    color: #414141;
    font-size: small !important;
}

.estado-abierto {
    position: absolute;
    border-radius: 5px;
    color: aliceblue;
    background-color: forestgreen;
    font-size: small;
    padding: 1.5px;
    font-weight: 600;
    margin: 3px;
    filter: none;
}

.span-delivery {
    position: absolute;
    border-radius: 5px;
    color: rgb(234, 234, 234); /* Asegura buen contraste */
    background-color: rgba(0, 0, 0, 0.5); /* Fondo negro semi-transparente */
    font-size: 14px; /* Aumentar un poco el tamaño */
    padding: 3px 6px; /* Aumenta el padding para más legibilidad */
    font-weight: 600;
    filter: none;
    right: 0;
    bottom: 0;
    margin-bottom: 5px; /* Un poco más de espacio con el borde */
    margin-right: 5px;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.8); /* Añadir sombra al texto */
    font-family: 'Poppins', sans-serif;
}


.estado-cerrado {
    position: absolute;

    color: aliceblue;
    border-radius: 5px;
    background-color: rgb(192, 31, 31);
    font-size: small;
    padding: 1.5px;
    font-weight: 600;
    margin: 3px;
    filter: none !important;

}



.direccion {
    display: inline;
    text-transform: capitalize;
    color: rgb(255, 255, 255);
    font-weight: 600;
    font-size: 10px;
    background-color: #007CBE;
    border-radius: 5px;
    padding: 2px 6px 2px 6px;
    ;
}

@media only screen and (max-width: 992px) {
    .titulo {
        margin-bottom: -5px;
        text-transform: capitalize;
    }

    .direccion {
        margin-bottom: -5px;
    }

    .horario-card {
        font-size: 12px;

    }
}

@media only screen and (max-width: 546px) {
    .titulo {
        color: #414141;
        font-size: 15px;
        text-transform: capitalize;
        margin-bottom: -4px;
    }


    .horario-card {
        color: #414141;
        font-weight: 600;
    }
}