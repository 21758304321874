@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Kanit:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@800&family=Rubik&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Kanit:wght@800&family=Montserrat:wght@800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@1,300;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&family=Tilt+Neon&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Istok+Web&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Tilt+Neon&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Signika+Negative&display=swap);
@-webkit-keyframes cart-animation {
  100% {
    -webkit-transform: translateY(0vh) scale(1);
            transform: translateY(0vh) scale(1);
  }

  0% {
    -webkit-transform: translateY(100vh) scale(10);
            transform: translateY(100vh) scale(10);
    opacity: 80%;
  }
}

@keyframes cart-animation {
  100% {
    -webkit-transform: translateY(0vh) scale(1);
            transform: translateY(0vh) scale(1);
  }

  0% {
    -webkit-transform: translateY(100vh) scale(10);
            transform: translateY(100vh) scale(10);
    opacity: 80%;
  }
}

.icono-carrito--animated {
  -webkit-animation: cart-animation 0.6s normal;
          animation: cart-animation 0.6s normal;
}

.header {
  width: 100%;
  height: 90px;
  line-height: 100px;
}

.container-texto-direccion {
  position: -webkit-sticky;
  position: sticky;
  top: 70px;
  z-index: 1000;
  padding: 10px 20px;
  padding-bottom: 0px;
}

.p-tu-direccion {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  color: #333;
}

.p-direccion {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #797474;
  font-weight: 700;
}

.p-direccion svg {
  margin-right: 5px;
  /* Espacio entre el icono y el texto */
}

.logo {
  text-align: center;
  display: flex;
}

.logo img {
  width: calc(100% - 45%);
  object-fit: contain;
}

.logo h5 {
  font-weight: 600;
  font-size: 1rem;
  color: #212245;
  margin-bottom: 0;
}

.menu a {
  font-weight: 600;
  font-size: 1.5rem;
  color: #212245;
  transition: 0.3s;
  margin-left: 50px;

}

.menu a:hover {
  color: #df2020;
}

.active__menu {
  color: #df2020 !important;
}

.cart__icon i,
.user i,
.mobile__menu i {
  font-size: 1.3rem;
  color: #212245;
  cursor: pointer;
}

.cart__icon {
  position: relative;
}

.cart__badge {
  position: absolute;
  top: 30px;
  right: -10px;
  background: #df2020;
  color: #fff;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  font-size: 0.8rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icono-carrito {
  font-size: 30px;
}

.icono-usuario {
  font-size: 25px;
}

.ubication-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #ededed;
  padding: 5px 10px; /* Asegura que haya solo un pequeño espacio a los lados */
  height: auto; /* Permite que la altura se ajuste automáticamente al contenido */
}

.container-texto-direccion {
  margin: 0; /* Elimina cualquier margen */
  padding: 0; /* Elimina cualquier padding */
}

.p-tu-direccion,
.p-direccion {
  margin: 0; /* Elimina márgenes */
  padding: 0; /* Elimina padding */
  line-height: 1.2; /* Ajusta la altura de la línea para hacer el texto más compacto */
  font-size: 12px;
}

.div-p-cambiar-direccion {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  height: auto; /* Asegura que el div se ajuste a su contenido */
}

.p-cambiar {
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 15px; /* Ajusta el tamaño de la fuente si es necesario */
  line-height: 1; /* Reduce el espaciado entre líneas */
  font-weight: 600;
  padding-right: 15px;
  color: #666666;
}


.icono-menu {
  font-size: 30px;
}

.sticky-ubication {
  position: -webkit-sticky;
  position: sticky;
  top: 70px; /* Ajusta el valor para que esté debajo del header */
  z-index: 999; /* Asegura que esté sobre otros elementos */
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Para dar un efecto de caja fija */
}

.mobile__menu {
  font-weight: 600;
  display: none;
}

.header__shrink {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  background: #E8E8E8;
  z-index: 99999;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

@media only screen and (max-width: 992px) {
  .logo img {
    width: calc(100% - 50%);
  }

  .logo h5 {
    font-size: 0.8rem;
  }

  .navigation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.425);
    z-index: 99;
    display: none;
  }

  .menu {
    position: absolute;
    top: 0;
    right: 0;
    width: 250px;
    height: 100%;
    background: #fff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 100;
    line-height: 20px;
  }

  .menu a {
    font-size: 1.5rem;
  }

  .mobile__menu {
    display: block;
  }

  .cart__icon i,
  .user i,
  .mobile__menu i {
    font-size: 1rem;
  }

  .show__menu {
    display: block;
  }
}



@media only screen and (max-width: 768px) {
  .header {
    width: 100%;
    height: 75px;
    line-height: 80px;
  }

  .logo img {
    width: calc(100% - 45%);
  }

  .logo h5 {
    margin-top: -55px;
  }
}
.item-list {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 16px;
  gap: 16px; /* Espacio entre items */
}

.item {
  position: relative;
  width: 150px; /* Ancho de cada item */
  height: 150px; /* Alto de cada item */
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.item-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.item-overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 8px;
  background: rgba(0, 0, 0, 0.6); /* Fondo negro opaco */
  display: flex;
  justify-content: center;
  align-items: center;
}

.item-name {
  color: white;
  font-size: 1rem;
  text-align: center;
  font-weight: bold;
}


.search__widget,
.sorting__widget select {
  padding: 7px 15px;
  border: 1px solid #fde4e4;
  border-radius: 5px;
  cursor: pointer;
}
.search__widget input {
  border-radius: 10px;
  padding: 7px;
  font-size: 20px;
  margin-bottom: -20px;
  
}

.div-reset-filtro-categorias{
  background-color: rgb(93, 93, 93);
  color: white;
  text-transform: capitalize;
  margin: 1px;
  padding: 10px 10px 10px 10px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Poppins', sans-serif;
}

.equis {
  background-color: rgb(193, 193, 193);
  padding: 10px;
  border-radius: 30%;
  color: black;
  font-weight: 800;
}

.div-titulo-tiendas{
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}
.parrafo-tiendas{
  line-height: 32px;
  font-weight: 500;
  margin-right: 10px;
  font-family: 'Poppins', sans-serif;
}

.slider-recomendados{
  margin-left: 10px;
  
}

.search__widget input:focus {
  outline: none;
}
.input-busqueda-local {
  border: 1px solid black;
  font-size: larger;
  font-weight: 800;
  padding: 3px;
}

.ri-search-line{
  position: absolute;
  right: 30px;
}

@media only screen and (max-width: 768px) {
  .search__widget {
    width: 100%;
    margin-bottom: -30px;
  }
  .search__widget input {
    width: 100%;
  }
  .sorting__widget select {
    width: 100% !important;
    font-size: 0.8rem;
  }

  .input-busqueda-local {
    border: 1px solid black;
    width: 20px;
  }
}

.paginationBttns {
  width: 80%;
  height: 40px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column-gap: 0.5rem;
  -webkit-column-gap: 0.5rem;
          column-gap: 0.5rem;
  list-style: none;
  margin-top: 30px;
}

.paginationBttns a {
  padding: 5px 13px;
  background: #212245;
  color: #fff !important;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 5px;
}

.paginationBttns a:hover {
  background: #df2020;
}

.contenedor {
    background-color: darkslateblue;
    padding: 5px;
    color: aliceblue;
    display: flex;
    justify-content: space-between;
    border-radius: 5px;
    background: linear-gradient(356deg, rgba(227, 226, 226, 0.684) 0%, rgba(196, 196, 199, 1) 18%, rgba(198, 198, 201, 0) 53%, rgba(135, 135, 135, 0.16290266106442575) 69%);
    cursor: pointer;
    max-height: 120px;
    grid-gap: 10px;
    gap: 10px;
    margin-bottom: 15px;



}

.contenedor-cerrado {
    background-color: darkslateblue;
    padding: 5px;
    color: aliceblue;
    display: flex;
    justify-content: space-between;
    border-radius: 5px;
    background: rgb(203, 203, 203);
    background: linear-gradient(356deg, rgba(203, 203, 203, 0.6839110644257703) 0%, rgba(196, 196, 199, 1) 18%, rgba(198, 198, 201, 0) 53%, rgba(135, 135, 135, 0.16290266106442575) 69%);
    cursor: pointer;
    max-height: 120px;
    opacity: 0.45;
    margin-bottom: 15px;


}


.tags-div {
    display: flex;
    flex-wrap: wrap;
    /* Agregar la propiedad flex-wrap */
    grid-gap: 2px;
    gap: 2px;
    margin-top: -15px;
}

.tag {
    margin-right: 1px;
    margin-bottom: 1px;
    background-color: rgba(255, 0, 0, 0.957);
    padding-left: 3px;
    padding-right: 3px;
    padding-top: 1px;
    padding-bottom: 1px;
    font-size: 10.5px;
    font-weight: 600;
    border-radius: 5px;
    text-transform: capitalize;
    color: #e2e2e2;
}

.horario-card {
    color: #414141;
    font-weight: 600;
}



.logoLocal {
    max-height: 110px;
    width: auto;
    border-radius: 7px;
}

.titulo {
    color: #414141;
    font-size: 15px;
    margin-bottom: -6px;
    text-transform: capitalize;
}

.texto-local {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 70%;
    margin-bottom: 10px;
}

.tiempo-icon {
    color: #414141;
}

.tiempo-div {
    display: flex;
    grid-gap: 2px;
    gap: 2px;
    margin-top: -8px;
}

.tiempo {
    color: #414141;
    font-size: small !important;
}

.estado-abierto {
    position: absolute;
    border-radius: 5px;
    color: aliceblue;
    background-color: forestgreen;
    font-size: small;
    padding: 1.5px;
    font-weight: 600;
    margin: 3px;
    -webkit-filter: none;
            filter: none;
}

.span-delivery {
    position: absolute;
    border-radius: 5px;
    color: rgb(234, 234, 234); /* Asegura buen contraste */
    background-color: rgba(0, 0, 0, 0.5); /* Fondo negro semi-transparente */
    font-size: 14px; /* Aumentar un poco el tamaño */
    padding: 3px 6px; /* Aumenta el padding para más legibilidad */
    font-weight: 600;
    -webkit-filter: none;
            filter: none;
    right: 0;
    bottom: 0;
    margin-bottom: 5px; /* Un poco más de espacio con el borde */
    margin-right: 5px;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.8); /* Añadir sombra al texto */
    font-family: 'Poppins', sans-serif;
}


.estado-cerrado {
    position: absolute;

    color: aliceblue;
    border-radius: 5px;
    background-color: rgb(192, 31, 31);
    font-size: small;
    padding: 1.5px;
    font-weight: 600;
    margin: 3px;
    -webkit-filter: none !important;
            filter: none !important;

}



.direccion {
    display: inline;
    text-transform: capitalize;
    color: rgb(255, 255, 255);
    font-weight: 600;
    font-size: 10px;
    background-color: #007CBE;
    border-radius: 5px;
    padding: 2px 6px 2px 6px;
    ;
}

@media only screen and (max-width: 992px) {
    .titulo {
        margin-bottom: -5px;
        text-transform: capitalize;
    }

    .direccion {
        margin-bottom: -5px;
    }

    .horario-card {
        font-size: 12px;

    }
}

@media only screen and (max-width: 546px) {
    .titulo {
        color: #414141;
        font-size: 15px;
        text-transform: capitalize;
        margin-bottom: -4px;
    }


    .horario-card {
        color: #414141;
        font-weight: 600;
    }
}
.categorias-container {
  display: flex;
  overflow-x: auto; /* Habilita el desplazamiento horizontal */
  white-space: nowrap; /* Evita el salto de línea de los elementos */
  padding: 10px;
  position: relative; /* Añade espacio alrededor del contenedor si es necesario */
}


  
.item-categoria {
  flex: 0 0 auto; /* Evita que los elementos cambien de tamaño */
  margin-right: 20px; /* Espacio entre las categorías */
  cursor: pointer; /* Cambia el cursor al pasar por encima */
}
.item-categoria-selected {
  flex: 0 0 auto; /* Evita que los elementos cambien de tamaño */
  margin-right: 20px; /* Espacio entre las categorías */
  cursor: pointer;
  background-color: rgb(223, 15, 15) ; /* Cambia el cursor al pasar por encima */
  border-radius: 5px;
  padding: 1px;
  color: white;
}

.item-categoria img {
  max-width: 80px; /* Ajusta el tamaño máximo de la imagen */
  height: auto; /* Ajusta la altura automáticamente según el ancho */
}
  
  .item-categoria h6 {
    margin-top: 5px;
    font-size: 12px;
    font-family: 'Poppins', sans-serif;
    
  }
  .item-categoria-selected img {
    max-width: 80px; /* Ajusta el tamaño máximo de la imagen */
    height: auto; /* Ajusta la altura automáticamente según el ancho */
  }
    
  .item-categoria-selected h6 {
      margin-top: 5px;
      font-size: 12px;
      font-family: 'Poppins', sans-serif;
      
    }
  
.div-imagen-prod {
    position: relative;
    display: flex;
    justify-content: center;
    
    

}

.categoria-prod-detalle {
    font-size: medium;
    font-weight: 600;
    color: #777777;
    margin-right: 10px;
    margin-top: 5px;
}

.div-botton-add-remove{
    display: flex;
    justify-content: space-between;
    border-radius: 15px;
    padding: 1px;
    grid-gap: 30px;
    gap: 30px;
    font-size: 25px;
    margin-top: 10px;
    box-shadow: 3px 3px 10px rgba(105, 105, 105, 0.709), -3px -3px 5px rgba(105, 105, 105, 0.098);
}

.obligatorio-tag{
    border: 2px solid #b3b3b3;
    border-radius: 0.4em;
    padding: 2px;
    font-weight: bold;
    font-size: 14px;
}

.container-listo-opcion-selected{
    display: flex;
    grid-gap:5px;
    gap:5px
}

.subopcion-unica-escogida{
    background-color: #878787;
    border-radius: 5px;
    padding: 2px;
    color:#fcfeff;
    font-size: 15px;
    font-weight: 600;
}

.icono-expand{
    font-size: 28px;
    margin-right: 10px;
    color: #878787;
}

.opciones-ocultas {
    height: 0; /* Establece la altura inicial en 0 */
    overflow: hidden; /* Oculta cualquier contenido desbordante */
    transition: height 2s ease ; /* Agrega una transición suave a la altura */
}



.listo-opcion {
    background-color: #00e53d;
    display: inline;
    border-radius: 5px;
    padding: 2px;
    color: #fcfeff;
    font-weight: 800;
    font-size: 14px;
}
.icono-delete{
    font-size: 21px;
}

.container-opcion-escogida{
    display: flex;
    justify-content: center;
    justify-items: center;
    background-color: #878787;
    border-radius: 5px;
    padding: 2px;
    color:#fcfeff;
    font-size: 15px;
    font-weight: 600;
    grid-gap: 2px;
    gap: 2px;
    text-transform: capitalize;
}



.cant-subopcion{
    font-size: 15px;
    font-weight: bolder;
    margin-top: 9px;
}


.boton-izquierda,
.boton-derecha {
    position: absolute;
    top: 15px;
    font-size: 24px;
    background-color: white;
    border: 0px;
    border-radius: 50%;
    padding: 1px 6px 1px 6px;
}


.boton-izquierda {
    left: 10px;
    /* Ajusta esto según tu diseño */
}

.boton-derecha {
    right: 10px;
    /* Ajusta esto según tu diseño */
}

.div-imagen-prod img {
    width: 100%;
    /* Ajusta el radio de borde según tu preferencia */
}

.div-texto-prod {
    -webkit-transform: translate(0, -6%);
            transform: translate(0, -6%);
    background-color: white;
    padding: 15px;
    border-radius: 30px 30px 10px 10px;
    /* Ajusta el radio de borde según tu preferencia */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding-bottom: 20%;
    min-height: 45vh;
    margin-bottom: 15%;
}

.div-texto-prod h1,
.div-texto-prod p {
    margin: 0;
}

.nombre-prod {
    display: flex;
    justify-content: space-between;
    text-transform: capitalize;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    font-size: 30px;
}

.descripcion-producto {
    font-size: 15px !important;
    font-weight: 400 !important;
    letter-spacing: -0.5px;
    color: rgb(119, 119, 119);
    font-family: 'Lato', 'sans-serif';
}

.boton-agregar {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #2aac4d;
    color: white;
    border: none;
    border-radius: 20px;
    font-size: large;
    cursor: pointer;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.7);
}

.opcion-div{
    display: flex;
    justify-content: space-between;
    padding-right: 5px;
    padding-left: 5px;
    border-top: 0.5px solid #b1b0b0;
    margin-bottom: 10px;
    text-transform: capitalize;
    
    
}

.subopcion-nombre{
    font-family: 'Lato', sans-serif;
    color: #696969;
}

.subopcion-precio{
    font-weight: 900;
    font-family: 'Roboto', sans-serif;
}
.icono-check{
    font-size: 25px;
    margin-top: 15px;
}

.div-boton-agregar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    background-color: #fcfeff;
    color: white;
    text-align: center;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.7);
}

.boton-agregar.animado {
    -webkit-animation: agrandar 0.5s ease-in-out;
            animation: agrandar 0.5s ease-in-out; /* Animación de agrandamiento */
}

@-webkit-keyframes agrandar {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    50% {
        -webkit-transform: scale(1.25);
                transform: scale(1.25);
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

@keyframes agrandar {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    50% {
        -webkit-transform: scale(1.25);
                transform: scale(1.25);
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

.precio-prod {
    font-size: 20px !important;
    margin-top: 10px !important;
}

.container-opciones{
    padding: 10px;
    border-radius: 15px;
    background-color: #fffefe;
    margin-bottom: 10px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.17);
}


.opcion-texto {
    display: flex;
    justify-content: space-between;
    border-radius: 10px;    
    padding-top: 5px;
    padding-bottom: 5px;
}

.opcion-titulo {
    font-weight: 900;
    text-transform: capitalize;
    font-size: 20px;

}
.opcion-limite{
    font-weight: 100;
    font-size: 11px;
    color: #777777;
}

.opcion-titulo-limite {
    font-weight: 800;
    font-family: 'Roboto', sans-serif;
    line-height: 10%;
    margin-bottom: 15px;


}

.opcion-descripcion {
    font-size: 11px;
    padding-top: 3px;
    color: #565656;
    text-transform: capitalize;
}










.icon-selected {
    position: absolute;
    right: 20px;
    top: 30%;
    font-size: 20px;
}

.icon-selected-dos {
    position: absolute;
    right: 20px;
    top: 7%;
    font-size: 20px;
}

.opciones-div {
    margin-top: 20px;
    font-family: 'Roboto', sans-serif;

}

/* Extra Small Devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .div-texto-prod {
        padding: 10px;
        min-height: auto;
    }

    .nombre-prod {
        font-size: 24px;
    }

    .descripcion-producto {
        font-size: 14px;
    }

    .boton-agregar {
        font-size: medium;
        padding: 8px 16px;
    }

    .div-imagen-prod img {
        width: 100%;
    }
}

/* Small Devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .div-texto-prod {
        padding: 15px;
    }

    .nombre-prod {
        font-size: 28px;
    }

    .descripcion-producto {
        font-size: 15px;
    }

    .boton-agregar {
        font-size: large;
        padding: 10px 20px;
    }
}

/* Medium Devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .div-texto-prod {
        padding: 20px;
    }

    .nombre-prod {
        font-size: 30px;
    }

    .descripcion-producto {
        font-size: 16px;
    }

    .boton-agregar {
        font-size: large;
        padding: 12px 24px;
    }
}

/* Large Devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .div-texto-prod {
        padding: 25px;
    }

    .nombre-prod {
        font-size: 32px;
    }

    .descripcion-producto {
        font-size: 17px;
    }

    .boton-agregar {
        font-size: large;
        padding: 14px 28px;
    }
}

/* Extra Large Devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .div-texto-prod {
        padding: 30px;
        width: 35%;
        margin: auto;
        margin-bottom: 5%;
    }

    .div-imagen-prod img {
        width: 28%;
        margin: auto;
        border-radius: 10%; /* Centra la imagen */
    }

    .nombre-prod {
        font-size: 34px;
    }

    .descripcion-producto {
        font-size: 18px;
    }

    .boton-agregar {
        font-size: x-large;
        padding: 16px 32px;
    }
}

.common__section {

  background: rgb(2,0,36);
background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%);

  padding: 80px 0px;
}
th {
  text-align: center;
}

.nombre-prod-carr {
  font-weight: 600;
  color: rgb(79, 78, 78);
}

.nombre-itemid-carr {
  color: #bd5454;
  font-weight: 700;
  text-transform: capitalize;
  
}
.cart__page-btn{
  display: flex;
}
.ri-delete-bin-line{
  font-size: medium;
  font-weight: 800;
}

.precio-prod-carr {
  font-weight: 700;
}

.cart__img-box {
  width: 80px;
}

.cart__img-box img {
  width: 100%;
}

.cart__item-del i {
  cursor: pointer;
}

.cart__page-btn button a:hover {
  color: #fff;
}

.cart__subtotal {
  color: #df2020;
  font-size: 1.5rem;
}

@media only screen and (max-width: 768px) {

  th,
  td {
    font-size: 0.8rem;
  }
}
.product-summary-table {
  width: 100%;
  margin-top: 20px;
}

.product-summary-table table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.product-summary-table th,
.product-summary-table td {
  padding: 1px;
  border: 1px solid #ddd;
  text-align: center;
  font-size: 13px;
}

.mapa-checkout{
  width: 400px;
  height: 400px;
}


.direccion-pedido-checkout{
  font-size: small;
  line-height: 100%;
  font-family: "Poppins", serif;
  font-weight: 600;
}

.p-direccion-checkout{
  font-weight: 500;
  font-size: 14px;
  color: #626262;
}

.mensaje-ubicacion-cliente {
  position: fixed; /* o absolute dependiendo de tu necesidad */
  top: 15%; /* distancia desde arriba */
  left: 50%; /* lo posiciona al centro */
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); /* centra el elemento horizontalmente */
  font-family: "Poppins", serif;
  font-weight: 500;
  padding: 12px 10px;
  max-width: 80%; /* para que no ocupe todo el ancho en pantallas grandes */
  width: 100%;
  font-size: 22px;
  background-color: #373737ce;
  color: #f4f4f4;
  border: 1px solid #ffeeba;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* sombra sutil */
  z-index: 1000; /* asegura que esté por encima del mapa */
}

.icon-alert{
  font-size: 40px;
  width: 100%;
  color: yellow;
  background-color: #004690;
  border-radius: 5px;
}

.icon-check{
  font-size: 20px;
  
}

.product-summary-table th {
  background-color: #f4f4f4;
  font-weight: bold;
  color: #333;
}

.product-summary-table td {
  font-size: 13px;
  color: #555;
  line-height: 105%;
}

.product-summary-table td:first-child {
  width: 8%;
}

.product-summary-table td:nth-child(2) {
  text-align: left;
}

.product-summary-table td:last-child {
  font-weight: bold;
  color: #007bff;
  white-space: nowrap;
  font-size: 14px;
}

.payment-buttons {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  /* Espacio entre los botones */
  justify-content: center;
  margin-bottom: 10px;
  margin-top: 15px;
}


.text-resumen {
  margin-top: 20px;
}

.payment-button {
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  background-color: transparent;
  /* Fondo transparente */
  border: 2px solid #ccc;
  /* Borde gris claro */
  border-radius: 8px;
  /* Borde redondeado */
  padding: 10px;
  cursor: pointer;
  transition: border-color 0.2s, -webkit-transform 0.2s;
  transition: transform 0.2s, border-color 0.2s;
  transition: transform 0.2s, border-color 0.2s, -webkit-transform 0.2s;
}

.payment-button img {
  width: 50px;
  /* Tamaño de la imagen */
  height: 50px;
}

.payment-button span {
  font-size: 12px;
  /* Tamaño de la fuente para el texto */
  color: #585858;
}

.payment-button:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  /* Efecto de agrandamiento al pasar el ratón */
  border-color: #007bff;
  /* Cambiar el color del borde al pasar el ratón */
}

.payment-button.active {
  border-color: #007bff;
  /* Borde azul para el botón seleccionado */
}


.checkout__bill {
  background: #fde4e4;
  padding: 30px;
}

.checkout__total {
  border-top: 1px solid #ddd;
  padding-top: 30px;
}

.product-summary {
  display: flex;
  justify-content: space-between;
  /* Distribuye los elementos en el ancho del contenedor */
  align-items: center;
  /* Alinea verticalmente los elementos */

  border-bottom: 1px solid #ccc;
  /* Línea divisoria entre productos */
  margin-bottom: 10px;
  /* Espacio inferior entre productos */
}

.product-quantity {
  width: 10%;
  /* Ajusta el ancho de la cantidad */
  font-weight: bold;
  /* Hace que la cantidad resalte */
}

.product-name {
  width: 60%;
  /* Ajusta el ancho del nombre */
  text-align: left;
  /* Alineación a la izquierda */
  font-size: 14px;
}

.product-price {
  width: 30%;
  /* Ajusta el ancho del precio */
  text-align: right;
  /* Alineación a la derecha */
  font-weight: bold;
  /* Hace que el precio resalte */
  color: #007bff;
  /* Color del precio */
}



.container-obtener-ubicacion {
  display: flex;
  flex-direction: column;
  height: 100vh;
  /* Contenedor principal ocupa toda la pantalla */
}

.error {
  color: red;
  font-size: 0.9rem;
  margin-top: 5px;
}

.container-mensaje-ubicacion {
  margin: 5%;
}

.button-entendido-ubicacion {
  background-color: #009205;
  border: 0px;
  color: white;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 10px;
  text-align: center;
  font-weight: 600;
  border-radius: 15px;
  margin-top: 15%;
}

.div-texto-mueve-mapa {
  position: absolute;
  top: 0;
}

.mapa {
  position: relative;
  height: 90%;
  /* El mapa ocupa el 70% de la altura de la pantalla */
  width: 100%;
  /* El mapa ocupa el 100% del ancho */
}

.container-mensaje-ubicacion {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  /* Espacio entre los bloques */
  padding: 20px;
  background-color: #f7f7f7;
  /* Color de fondo suave */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Sombra ligera */
  width: 100%;
  max-width: 600px;
  /* Máximo ancho */
  margin: 0 auto;
  /* Centrar en la página */
}

.container-mensaje-ubicacion h2 {
  font-size: 24px;
  margin-bottom: 10px;
  /* Espacio debajo del título */
  color: #333;
  text-align: center;
}

.container-mensaje-ubicacion h4 {
  font-size: 18px;
  margin-bottom: 5px;
  /* Espacio debajo del subtítulo */
  color: #333;
}

.container-mensaje-ubicacion p {
  font-size: 13px;
  margin-bottom: 10px;
  /* Espacio debajo del párrafo */
  color: #666;
  /* Color gris suave */
}

.errorCampo {
  color: white !important;
  padding: 2px;
  border-radius: 5px;
  font-size: 1rem !important;
  font-weight: bold;
  margin-top: 5px;
  background-color: red;
}

.ubication-div {
  display: flex;
  justify-content: space-between;
}

.input-direccion,
.input-referencia,
.input-telefono {
  width: 100%;
  padding: 10px;
  font-size: 15px;

  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease;
  color: #494949;
}

.input-direccion::-webkit-input-placeholder, .input-telefono::-webkit-input-placeholder, .input-referencia::-webkit-input-placeholder {
  color: #888;
  font-style: italic;
  font-size: 15px;
}

.input-direccion::placeholder,
.input-telefono::placeholder,
.input-referencia::placeholder {
  color: #888;
  font-style: italic;
  font-size: 15px;
}

.input-direccion:focus,
.input-referencia:focus {
  border-color: #007bff;
  border: 2px solid #007bff;
}


.button-guardar-direccion {
  background-color: #28a745;
  /* Color de fondo verde */
  color: #fff;
  /* Color del texto en blanco */
  border: none;
  /* Sin borde */
  border-radius: 5px;
  /* Bordes redondeados */
  padding: 10px 20px;
  /* Espaciado interno */
  font-size: 16px;
  /* Tamaño de fuente */
  cursor: pointer;
  /* Cambiar cursor a puntero */
  transition: background-color 0.3s ease, -webkit-transform 0.2s ease;
  transition: background-color 0.3s ease, transform 0.2s ease;
  transition: background-color 0.3s ease, transform 0.2s ease, -webkit-transform 0.2s ease;
  /* Transiciones suaves para color de fondo y transformación */
}

.button-guardar-direccion:hover {
  background-color: #218838;
  /* Color de fondo más oscuro al pasar el mouse */
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  /* Efecto de agrandamiento al pasar el mouse */
}

.button-guardar-direccion:disabled {
  background-color: #c0c0c0;
  /* Color gris para botón deshabilitado */
  cursor: not-allowed;
  /* Cambiar cursor a no permitido */
}


@media (min-width: 768px) {
  .container-mensaje-ubicacion {
    grid-gap: 30px;
    gap: 30px;
  }

  .container-mensaje-ubicacion div {
    flex: 1 1;
  }
}


.button-send {
  background-color: #28a745;
  /* Verde estilo éxito */
  color: white;
  border: none;
  border-radius: 5px;
  padding: 12px 24px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, -webkit-transform 0.2s ease;
  transition: background-color 0.3s ease, transform 0.2s ease;
  transition: background-color 0.3s ease, transform 0.2s ease, -webkit-transform 0.2s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Sombra ligera */
  width: 100%;
  /* Se ajusta al ancho del contenedor */
}

.button-send:hover {
  background-color: #218838;
  /* Color más oscuro al pasar el mouse */
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
  /* Efecto de elevación */
}

.button-send:active {
  background-color: #1e7e34;
  /* Aún más oscuro cuando está activo */
  -webkit-transform: translateY(0);
          transform: translateY(0);
  /* Quita la elevación al hacer clic */
}

.button-send:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(72, 180, 97, 0.5);
  /* Resaltar en foco */
}

.div-buttons-ubi {
  display: flex;
  justify-content: center;
}


.button-borrar-ubi {
  color: white;
  border: 0px;
  background-color: rgb(179, 40, 40);
  font-weight: 600;
  width: 100%;
  padding: 5px;
  border-radius: 15px;
}


.button-ubicacion {
  position: fixed; /* o absolute si prefieres que se mantenga dentro del contenedor padre */
  bottom: 20px; /* distancia desde abajo */
  right: 20px; /* distancia desde la derecha */
  background-color: green;
  border-radius: 10px;
  color: white;
  font-weight: bold;
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  /* Opcional: para que el botón no ocupe todo el ancho */
  width: auto;
  min-width: 200px; /* o el ancho que prefieras */
  /* Opcional: añadir sombra para que se vea más flotante */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.obtener-ubicacion-text {
  padding-top: 2px;
}


.logo-pago {
  width: 23px;
}

.img-pago {
  width: 70px;
}

.wrapper-check {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 5px;
  padding: 20px 15px;
}

.wrapper-check .option {
  background: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 10px;
  border-radius: 5px;
  cursor: pointer;
  padding: 0 10px;
  border: 2px solid lightgrey;
  transition: all 0.3s ease;
}

.wrapper-check .option .dot {
  height: 20px;
  width: 20px;
  background: #d9d9d9;
  border-radius: 50%;
  position: relative;
}

.wrapper-check .option .dot::before {
  position: absolute;
  content: "";
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  background: #0069d9;
  border-radius: 50%;
  opacity: 0;
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
  transition: all 0.3s ease;
}

input[type="radio"] {
  display: none;
}

#option-1:checked:checked~.option-1,
#option-2:checked:checked~.option-2,
#option-3:checked:checked~.option-3 {
  border-color: #0069d9;
  background: #0069d9;
}

#option-1:checked:checked~.option-1 .dot,
#option-2:checked:checked~.option-2 .dot,
#option-3:checked:checked~.option-3 .dot {
  background: #fff;
}

#option-1:checked:checked~.option-1 .dot::before,
#option-2:checked:checked~.option-2 .dot::before,
#option-3:checked:checked~.option-3 .dot::before {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.wrapper .option span {
  font-size: 20px;
  color: #808080;
}

#option-1:checked:checked~.option-1 span,
#option-2:checked:checked~.option-2 span,
#option-3:checked:checked~.option-3 span {
  color: #fff;
}

@media only screen and (max-width: 768px) {
  .checkout__bill h6 {
    font-size: 0.9rem;
  }

  .checkout__bill {
    margin-top: 40px;
  }
}


.checkout-summary-card {
  background-color: #d7d7d7;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  padding: 15px;
  margin-top: 20px;
}

.checkout-card-body {
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
  margin: 0 5px 0 5px;
}



.checkout-summary-item {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  color: #555;
}

.checkout-summary-total {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  font-weight: bold;
  padding-top: 5px;
  border-top: 1px solid #ccc;
}
h1 {
  font-family: 'Nunito', sans-serif;
  color: #2c2c2c;
}
.single__product-content {
  padding-left: 20px;
}

.static-bar {
  display: flex;
  justify-content: space-between;
  margin-left: -10px;
  background-color: #ffffff;
  padding: 10px;
  padding-left: 30px;
  padding-right: 50px;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
}
.barra-bottom-p{
  margin-bottom: 1px;
  
}

.td-producto{
  text-transform: capitalize;
}

.td-local{
  font-weight: 600;
  text-transform: capitalize;
}

.div-barra-bottom-button{
  display: flex;
  margin-top: 5px;
  padding: 5px;
  justify-content: center;
  align-items: center;
  height: 100%; 
  background-color: #22d251;
  font-size: 18px;
  font-weight: 600;
  color: white ;
  border-radius: 15px;
  box-shadow: rgba(0, 114, 23, 0.6) 0px 3px 8px;
  
}

.div-barra-bottom-text{
  line-height: 130%;
}

.barra-bottom-precio{
  font-weight: bolder ;
  font-size: 30px;
  font-family: 'Roboto', sans-serif;

}

.contenedor-categorias {
  max-height: 120px;
  margin-top: 13%;
  margin-bottom: 30px;
  display: flex;
  overflow-x: auto;
  padding: 5px;
  text-transform: capitalize;
}

.contenedor-producto {
  border-bottom: 1px solid #9B9B9B;
  display: flex !important;
  justify-content: space-between !important;

}


.container-search {
  position: relative;
  ;
}


.ri-search-line {
  font-size: x-large;
  position: absolute;
  top: 13px;
  margin-left: 5px;
}


.titulo-producto {
  display: flex;
  grid-gap: 0.6rem;
  gap: 0.6rem;
  vertical-align: middle;
  line-height: 25px;
}

.texto-producto {
  vertical-align: middle;
  display: flex;
  flex-direction: column;
}

.categoria-producto {
  font-size: 13px;
  font-weight: 600;
  color: #961c1c;
  margin-top: 3px;
}

.nombre-producto {
  font-size: 18px;
  font-weight: 700;
  text-transform: capitalize;
  margin-top: 3px;
}

.descripcion-producto {
  font-size: 12px;
  font-weight: 600;
  max-width: 100%;
}

.descripcion-producto::first-letter{
  text-transform: uppercase;
}


.container-subs {
  display: flex !important;
  justify-content: space-between !important;
  vertical-align: middle;
  line-height: 10px;
}

.productos-container-general{
  margin-bottom: 35%;
}



.nombre-subs {
  font-size: 14px;
  font-weight: 600;
  margin-right: 20px;
  vertical-align: bottom;
  text-transform: capitalize;

}

.contenedor-imagen img {
  max-width: 100px;
  border-radius: 5px;
}

.contenedor-imagen {
  display: flex;
  justify-items: center;
  align-items: center;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.precio-producto {
  font-size: larger;
  margin-top: -10px;
  margin-bottom: -1px;
  font-weight: 800;
}

.search-term{
  text-transform: capitalize;
}

.navegacion {

  align-items: center;
  min-width: 100px;
  background-clip: padding-box;
  background-color: rgb(234, 227, 227);
  border: 1px solid transparent;
  border-radius: .25rem;
  box-shadow: rgba(0, 0, 0, 0.2) 0 1px 3px 0;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  justify-content: center;
  margin: 0;
  min-height: 3rem;
  padding: 2px;
  height: 70px;
  margin: 2px;
}

.navegacion-selected {

  align-items: center;
  min-width: 100px;
  background-clip: padding-box;
  background-color: #17526b;
  transition: background-color 1s ease 0s;
  border: 1px solid transparent;
  border-radius: .25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  margin: 0;
  min-height: 2.5rem;
  padding: 2px;
  height: 70px;
  margin: 2px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}



.logo-local {
  max-width: 170px;
  border-radius: 15px;
}

.input-busqueda {
  width: 100%;
  height: 50px;
  font-size: medium;
  border: 1px solid;
}

.container-subs {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

}

.info-local {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 200px;
}

.product__price {
  display: flex;
  align-items: center;
  text-transform: capitalize;
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
          column-gap: 1rem;
  margin-bottom: 0;
  font-size: 40px;

}

.product__price span {
  font-size: 2rem;
}

.category {
  font-weight: 600;
  color: #212245;
  display: flex;
  align-items: center;
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
          column-gap: 1rem;
  margin-top: 10px;
}

.category span {
  padding: 5px 10px;
  background: #fde4e4;
  color: #212245;
  border-radius: 5px;
}

.tabs {
  border-bottom: 1px solid #fde4e4;
}

.tabs h6 {
  cursor: pointer;
}

.tab__content {
  padding: 30px 0px;
}

.tab__content p {
  line-height: 30px;
  color: #212245b6;
}

.tab__active {
  color: #df2020;
}

.form {
  width: 60%;
  margin: auto;
  background: #fde4e4;
  padding: 20px;
  border-radius: 5px;
}

.form__group {
  margin-bottom: 30px;
  margin-top: 10px;
}

.input-prefix {
    display: flex;
    align-items: center;
}

.prefix {
    padding: 5px;
    border-right: none;
    border-radius: 4px 0 0 4px;
}

input[type="tel"] {
    border: 1px solid #ccc;
    border-left: none;
    padding: 5px;
    border-radius: 0 4px 4px 0;
    width: 100%;
}

.form__group input,
.form__group textarea {
  width: 100%;
  background: transparent;
  padding: 7px 20px;
  border: none;
  border-bottom: 1px solid #2122454c;
}

.form__group input:focus,
.form__group textarea:focus {
  outline: none;
}

.user__name {
  font-weight: 600;
  color: #212245;
}

.enlaces-local {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  font-weight: 600;
}

.ubicacion-local {
  font-size: 20px;
  color: #212245;
}

.descripcion-subs {
  color: #787878;
  font-weight: 600;
}


.info-div {
  display: flex;
  flex-direction: row;
  margin-bottom: 3px;
  text-indent: 8px;
  font-size: 0.9rem;
  vertical-align: middle;
}

.adicionalPorTaper {
  font-size: 18px;
  font-weight: 600;
  text-decoration: underline;
}





.img__item {
  cursor: pointer;
}

.related__Product-title {
  font-size: 1.4rem;
}

@media only screen and (max-width: 768px) {
  .product__title {
    font-size: 1.2rem;
  }

  .img-carta{
    width: 450px;
  }

  .info-local {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 150px;
    margin-right: 10px;
  }


  .descripcion-subs {
    font-size: 11px;
    max-width: 57%;
    
  }

  .ri-search-line {
    font-size: x-large;
    position: absolute;
    top: 13px;
    margin-left: 5px;
  }

  .container-search {
    position: relative;
    ;
  }

  .input-busqueda {
    margin-bottom: 40px;
  }

  .product__price,
  .product__price span {
    font-size: 2rem;
  }

  .category,
  .category span {
    font-size: 0.8rem !important;
  }

  .tabs {
    margin-top: 30px;
  }

  .tabs h6 {
    font-size: 0.9rem;
  }

  .tab__content p,
  .user__name,
  .feedback__text {
    font-size: 0.8rem;
  }

  .user__email {
    font-size: 0.7rem;
  }

  .form {
    width: 100% !important;
    margin-top: 50px;
  }

  .form__group input::-webkit-input-placeholder, .form__group textarea::-webkit-input-placeholder {
    font-size: 0.8rem;
  }

  .form__group input::placeholder,
  .form__group textarea::placeholder {
    font-size: 0.8rem;
  }

  .related__Product-title {
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 576px) {
  .product__images {
    display: flex;
    flex-direction: row;
  }

  .product__main-img {
    margin-bottom: 20px;
  }

  .img-carta{
    width: 350px;
  }
}
.horario-local{
  font-size: 20px;
  font-weight: 600;

}

.contenedor-mapa {
  text-align: center;
}

img {
  max-width: 100%;
  height: auto;
}

.icono {
  font-size: 40px;
}

.nombre {
  display: flex;
  justify-content: space-between;
  margin-top: -20px;
  font-family: 'Kanit', sans-serif;
  text-transform: capitalize ;
  font-size: 30px;
}

.ubicacion {
  text-transform: capitalize;
  font-size: small;
  margin-top: 10px;
  color: #4c4c4c;

}

.logo-local{
  width: 50% !important;
}

.intro {
  display: flex;
  justify-content: center;
  grid-gap: 40px;
  gap: 40px;
}


.profile-card {
  height: auto;
  text-align: center;
  margin: 20px auto;
  box-shadow: 0px 0px 18px #ccc;
}


.profile-card .profile-body {
  background-color: #fff;
  padding: 20px 40px 40px 40px;
  border-radius: 20px;
}

.profile-card .profile-body .author-img {
  margin-top: -80px;
  margin-left: -70%;
  margin-bottom: 20px;
}

.profile-card .profile-body .author-img img {
  width: 170px;
  height: 170px;
  border-radius: 50%;
  padding: 5px;
  background-color: var(--white);
}



.profile-header {
  height: 300px;
  overflow: hidden;
  margin: 10px;
  position: relative;
  border-radius: 20px;
  object-fit: contain;
}

.banner {
  position: absolute;
  object-fit: fill;
  left: -100%;
  right: -100%;
  top: -100%;
  bottom: -100%;
  margin: auto;
  min-height: 100%;
  max-width: 100%;
  z-index: -1;
  border-radius: 20px;
  
}

.profile-card .profile-body .intro {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6;
  margin: 20px 0px 30px 0px;

}

.social-icon ul {
  list-style-type: none;
}

.social-icon ul li {
  display: inline-block;
}

.social-icon ul li a {
  margin-right: 5px;
  width: 35px;
  height: 35px;
  border: 1px solid #ddd;
  display: block;
  border-radius: 50%;
  transition: all 0.5s ease-out;
}

.social-icon ul {
  margin: 0;
  padding: 0;
}

.social-icon ul li a:hover {
  background-color: var(--dark);
  border: 1px solid var(--dark);
}

.social-icon ul li a i {
  line-height: 35px;
  color: #666;
}

.social-icon ul li a i:hover,
.social-icon ul li a:hover i {

  color: var(--white);
}

.profile-body {}

@media only screen and (max-width: 768px) {
  .profile-header {
    height: 190px;
    border-radius: 20px;
  }

 

  .contenedor-mapa {
    max-width: 600px; /* Establece el ancho máximo del contenedor */
    margin: 0 auto; /* Centra el contenedor en pantallas más grandes */
  }

  
}
.contenedor-prod {
    display: flex;
    justify-content: space-between;
    position: relative;
    background-color: #fff;
    margin-bottom: 5px;
    border-radius: 10px;
    padding: 8px;
    max-height: 140px;
}

.desde-text{
    font-size: medium;
    font-weight: 500;
}

.texto-prod {
    max-width: 70%;
}

.titulo-prod {
    font-size: 14px;
    font-family: 'Rubik', sans-serif;
    text-transform: capitalize;
}

.descripcion-prod {
    font-size: 12.5px;
    line-height: 115%;
    font-weight: 500;
    color: rgb(126, 126, 126);
    font-family: "Lato", sans-serif;
    letter-spacing: -0.6px;
}

.descripcion-prod::first-letter {
    text-transform: uppercase;
}



.logo-prod {
    max-width: 30%;
    position: relative;
    display: flex;
}

.precio-prod {
    font-family: 'Kanit', sans-serif; 
    font-size: 17px;
    padding: 2px;
    border-radius: 5px;
    font-weight: 600;
}

.imagen-prod {
    width: 100%;
    height: auto;
    border-radius: 10px;
    object-fit: cover;
}
.cart__item-info img {
  width: 35px;
  height: 35px;
  object-fit: cover;
}

.cart__product-title {
  font-size: 0.65rem;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 0;
}

.cart__product-price {
  font-size: 0.8rem;
  padding-bottom: -10px;
}

.cart__product-price span {
  font-size: 0.9rem;
  font-weight: 600;
  color: #df2020;
}
.boton-confirmar{
  font-weight: 700;
  
}


.increase__decrease-btn {
  background: #707070;
  color: white;
  font-weight: 600;
  font-size: large;
  border-radius: 5px;
  width: 150px;
}

.increase__btn,
.decrease__btn {
  cursor: pointer;
}

.delete__btn {
  font-size: 1rem;
  position: absolute;
  right: 20px;
}

.cart__item {
  margin-bottom: 5px;
  border-bottom: 1px solid #fde4e4;
}

@media only screen and (max-width: 576px) {
  .delete__btn {
    font-size: 2rem;
  }
}

.cart__container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.639);
  z-index: 99999;
}

.cart {
  position: absolute;
  top: 0;
  right: 0;
  width: 400px;
  height: 100%;
  background: #fff;
  z-index: 999999;
}

.cart__close {
  width: 100%;
  height: 60px;

  padding: 10px 20px;
}

.cart__close span i {
  font-size: 1rem;
  background: #212245;
  padding: 5px;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
}
.cart__item-list {
  height: calc(100vh - 140px);
  overflow-y: scroll;
}

.cart__bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 5px 20px;
  width: 100%;
  height: 80px;
  margin-bottom: 15%;
  background: #df2020;
}

.cart__bottom h6 {
  font-size: 1rem;
  color: #fff;
}

.cart__bottom h6 span {
  font-size: 1.3rem;
  font-weight: 600;
}

.cart__bottom button {
  border: none;
  padding: 4px 20px;
  border-radius: 5px;
  background: #fff;
  color: #212245;
}

.cart__bottom button a {
  color: #212245;
  font-weight: 600;
}

.boton-confirmar{
  margin-bottom: 10%;
}

@media only screen and (max-width: 576px) {
  .cart {
    width: 300px !important;
  }
}





/* google fonts */


/* reset default style */

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* base style */

body {
  background: #E8E8E8;
  font-family: "Istok Web", sans-serif;
}


h2,
h3,
h4,
h5,
h6 {
  font-family: 'Signika Negative', sans-serif;

}

a {
  text-decoration: none;
  color: unset;
}

h1,
h2 {
  font-size: 2.1rem;
  font-weight: 600;
  color: #212245;
}

section {
  padding: 0px 0px;
}

@media only screen and (max-width: 768px) {
  section {
    padding: 00px 0px;
  }

  h1,
  h2 {
    font-size: 1.5rem;
  }
}


