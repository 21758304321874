.item-list {
  display: flex;
  flex-wrap: wrap;
  gap: 16px; /* Espacio entre items */
}

.item {
  position: relative;
  width: 150px; /* Ancho de cada item */
  height: 150px; /* Alto de cada item */
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.item-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.item-overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 8px;
  background: rgba(0, 0, 0, 0.6); /* Fondo negro opaco */
  display: flex;
  justify-content: center;
  align-items: center;
}

.item-name {
  color: white;
  font-size: 1rem;
  text-align: center;
  font-weight: bold;
}


.search__widget,
.sorting__widget select {
  padding: 7px 15px;
  border: 1px solid #fde4e4;
  border-radius: 5px;
  cursor: pointer;
}
.search__widget input {
  border-radius: 10px;
  padding: 7px;
  font-size: 20px;
  margin-bottom: -20px;
  
}

.div-reset-filtro-categorias{
  background-color: rgb(93, 93, 93);
  color: white;
  text-transform: capitalize;
  margin: 1px;
  padding: 10px 10px 10px 10px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Poppins', sans-serif;
}

.equis {
  background-color: rgb(193, 193, 193);
  padding: 10px;
  border-radius: 30%;
  color: black;
  font-weight: 800;
}

.div-titulo-tiendas{
  display: flex;
  gap: 10px;
}
.parrafo-tiendas{
  line-height: 32px;
  font-weight: 500;
  margin-right: 10px;
  font-family: 'Poppins', sans-serif;
}

.slider-recomendados{
  margin-left: 10px;
  
}

.search__widget input:focus {
  outline: none;
}
.input-busqueda-local {
  border: 1px solid black;
  font-size: larger;
  font-weight: 800;
  padding: 3px;
}

.ri-search-line{
  position: absolute;
  right: 30px;
}

@media only screen and (max-width: 768px) {
  .search__widget {
    width: 100%;
    margin-bottom: -30px;
  }
  .search__widget input {
    width: 100%;
  }
  .sorting__widget select {
    width: 100% !important;
    font-size: 0.8rem;
  }

  .input-busqueda-local {
    border: 1px solid black;
    width: 20px;
  }
}
