@keyframes cart-animation {
  100% {
    transform: translateY(0vh) scale(1);
  }

  0% {
    transform: translateY(100vh) scale(10);
    opacity: 80%;
  }
}

.icono-carrito--animated {
  animation: cart-animation 0.6s normal;
}

.header {
  width: 100%;
  height: 90px;
  line-height: 100px;
}

.container-texto-direccion {
  position: sticky;
  top: 70px;
  z-index: 1000;
  padding: 10px 20px;
  padding-bottom: 0px;
}

.p-tu-direccion {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  color: #333;
}

.p-direccion {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #797474;
  font-weight: 700;
}

.p-direccion svg {
  margin-right: 5px;
  /* Espacio entre el icono y el texto */
}

.logo {
  text-align: center;
  display: flex;
}

.logo img {
  width: calc(100% - 45%);
  object-fit: contain;
}

.logo h5 {
  font-weight: 600;
  font-size: 1rem;
  color: #212245;
  margin-bottom: 0;
}

.menu a {
  font-weight: 600;
  font-size: 1.5rem;
  color: #212245;
  transition: 0.3s;
  margin-left: 50px;

}

.menu a:hover {
  color: #df2020;
}

.active__menu {
  color: #df2020 !important;
}

.cart__icon i,
.user i,
.mobile__menu i {
  font-size: 1.3rem;
  color: #212245;
  cursor: pointer;
}

.cart__icon {
  position: relative;
}

.cart__badge {
  position: absolute;
  top: 30px;
  right: -10px;
  background: #df2020;
  color: #fff;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  font-size: 0.8rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icono-carrito {
  font-size: 30px;
}

.icono-usuario {
  font-size: 25px;
}

.ubication-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #ededed;
  padding: 5px 10px; /* Asegura que haya solo un pequeño espacio a los lados */
  height: auto; /* Permite que la altura se ajuste automáticamente al contenido */
}

.container-texto-direccion {
  margin: 0; /* Elimina cualquier margen */
  padding: 0; /* Elimina cualquier padding */
}

.p-tu-direccion,
.p-direccion {
  margin: 0; /* Elimina márgenes */
  padding: 0; /* Elimina padding */
  line-height: 1.2; /* Ajusta la altura de la línea para hacer el texto más compacto */
  font-size: 12px;
}

.div-p-cambiar-direccion {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  height: auto; /* Asegura que el div se ajuste a su contenido */
}

.p-cambiar {
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 15px; /* Ajusta el tamaño de la fuente si es necesario */
  line-height: 1; /* Reduce el espaciado entre líneas */
  font-weight: 600;
  padding-right: 15px;
  color: #666666;
}


.icono-menu {
  font-size: 30px;
}

.sticky-ubication {
  position: sticky;
  top: 70px; /* Ajusta el valor para que esté debajo del header */
  z-index: 999; /* Asegura que esté sobre otros elementos */
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Para dar un efecto de caja fija */
}

.mobile__menu {
  font-weight: 600;
  display: none;
}

.header__shrink {
  position: sticky;
  top: 0;
  left: 0;
  background: #E8E8E8;
  z-index: 99999;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

@media only screen and (max-width: 992px) {
  .logo img {
    width: calc(100% - 50%);
  }

  .logo h5 {
    font-size: 0.8rem;
  }

  .navigation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.425);
    z-index: 99;
    display: none;
  }

  .menu {
    position: absolute;
    top: 0;
    right: 0;
    width: 250px;
    height: 100%;
    background: #fff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 100;
    line-height: 20px;
  }

  .menu a {
    font-size: 1.5rem;
  }

  .mobile__menu {
    display: block;
  }

  .cart__icon i,
  .user i,
  .mobile__menu i {
    font-size: 1rem;
  }

  .show__menu {
    display: block;
  }
}



@media only screen and (max-width: 768px) {
  .header {
    width: 100%;
    height: 75px;
    line-height: 80px;
  }

  .logo img {
    width: calc(100% - 45%);
  }

  .logo h5 {
    margin-top: -55px;
  }
}