th {
  text-align: center;
}

.nombre-prod-carr {
  font-weight: 600;
  color: rgb(79, 78, 78);
}

.nombre-itemid-carr {
  color: #bd5454;
  font-weight: 700;
  text-transform: capitalize;
  
}
.cart__page-btn{
  display: flex;
}
.ri-delete-bin-line{
  font-size: medium;
  font-weight: 800;
}

.precio-prod-carr {
  font-weight: 700;
}

.cart__img-box {
  width: 80px;
}

.cart__img-box img {
  width: 100%;
}

.cart__item-del i {
  cursor: pointer;
}

.cart__page-btn button a:hover {
  color: #fff;
}

.cart__subtotal {
  color: #df2020;
  font-size: 1.5rem;
}

@media only screen and (max-width: 768px) {

  th,
  td {
    font-size: 0.8rem;
  }
}