



/* google fonts */

@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Tilt+Neon&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Istok+Web&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Tilt+Neon&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Signika+Negative&display=swap');


/* reset default style */

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* base style */

body {
  background: #E8E8E8;
  font-family: "Istok Web", sans-serif;
}


h2,
h3,
h4,
h5,
h6 {
  font-family: 'Signika Negative', sans-serif;

}

a {
  text-decoration: none;
  color: unset;
}

h1,
h2 {
  font-size: 2.1rem;
  font-weight: 600;
  color: #212245;
}

section {
  padding: 0px 0px;
}

@media only screen and (max-width: 768px) {
  section {
    padding: 00px 0px;
  }

  h1,
  h2 {
    font-size: 1.5rem;
  }
}

