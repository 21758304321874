@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@800&family=Rubik&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@800&family=Montserrat:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@1,300;1,400&display=swap');
.contenedor-prod {
    display: flex;
    justify-content: space-between;
    position: relative;
    background-color: #fff;
    margin-bottom: 5px;
    border-radius: 10px;
    padding: 8px;
    max-height: 140px;
}

.desde-text{
    font-size: medium;
    font-weight: 500;
}

.texto-prod {
    max-width: 70%;
}

.titulo-prod {
    font-size: 14px;
    font-family: 'Rubik', sans-serif;
    text-transform: capitalize;
}

.descripcion-prod {
    font-size: 12.5px;
    line-height: 115%;
    font-weight: 500;
    color: rgb(126, 126, 126);
    font-family: "Lato", sans-serif;
    letter-spacing: -0.6px;
}

.descripcion-prod::first-letter {
    text-transform: uppercase;
}



.logo-prod {
    max-width: 30%;
    position: relative;
    display: flex;
}

.precio-prod {
    font-family: 'Kanit', sans-serif; 
    font-size: 17px;
    padding: 2px;
    border-radius: 5px;
    font-weight: 600;
}

.imagen-prod {
    width: 100%;
    height: auto;
    border-radius: 10px;
    object-fit: cover;
}