.cart__item-info img {
  width: 35px;
  height: 35px;
  object-fit: cover;
}

.cart__product-title {
  font-size: 0.65rem;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 0;
}

.cart__product-price {
  font-size: 0.8rem;
  padding-bottom: -10px;
}

.cart__product-price span {
  font-size: 0.9rem;
  font-weight: 600;
  color: #df2020;
}
.boton-confirmar{
  font-weight: 700;
  
}


.increase__decrease-btn {
  background: #707070;
  color: white;
  font-weight: 600;
  font-size: large;
  border-radius: 5px;
  width: 150px;
}

.increase__btn,
.decrease__btn {
  cursor: pointer;
}

.delete__btn {
  font-size: 1rem;
  position: absolute;
  right: 20px;
}

.cart__item {
  margin-bottom: 5px;
  border-bottom: 1px solid #fde4e4;
}

@media only screen and (max-width: 576px) {
  .delete__btn {
    font-size: 2rem;
  }
}
