@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

.categorias-container {
  display: flex;
  overflow-x: auto; /* Habilita el desplazamiento horizontal */
  white-space: nowrap; /* Evita el salto de línea de los elementos */
  padding: 10px;
  position: relative; /* Añade espacio alrededor del contenedor si es necesario */
}


  
.item-categoria {
  flex: 0 0 auto; /* Evita que los elementos cambien de tamaño */
  margin-right: 20px; /* Espacio entre las categorías */
  cursor: pointer; /* Cambia el cursor al pasar por encima */
}
.item-categoria-selected {
  flex: 0 0 auto; /* Evita que los elementos cambien de tamaño */
  margin-right: 20px; /* Espacio entre las categorías */
  cursor: pointer;
  background-color: rgb(223, 15, 15) ; /* Cambia el cursor al pasar por encima */
  border-radius: 5px;
  padding: 1px;
  color: white;
}

.item-categoria img {
  max-width: 80px; /* Ajusta el tamaño máximo de la imagen */
  height: auto; /* Ajusta la altura automáticamente según el ancho */
}
  
  .item-categoria h6 {
    margin-top: 5px;
    font-size: 12px;
    font-family: 'Poppins', sans-serif;
    
  }
  .item-categoria-selected img {
    max-width: 80px; /* Ajusta el tamaño máximo de la imagen */
    height: auto; /* Ajusta la altura automáticamente según el ancho */
  }
    
  .item-categoria-selected h6 {
      margin-top: 5px;
      font-size: 12px;
      font-family: 'Poppins', sans-serif;
      
    }
  