@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap');

.div-imagen-prod {
    position: relative;
    display: flex;
    justify-content: center;
    
    

}

.categoria-prod-detalle {
    font-size: medium;
    font-weight: 600;
    color: #777777;
    margin-right: 10px;
    margin-top: 5px;
}

.div-botton-add-remove{
    display: flex;
    justify-content: space-between;
    border-radius: 15px;
    padding: 1px;
    gap: 30px;
    font-size: 25px;
    margin-top: 10px;
    box-shadow: 3px 3px 10px rgba(105, 105, 105, 0.709), -3px -3px 5px rgba(105, 105, 105, 0.098);
}

.obligatorio-tag{
    border: 2px solid #b3b3b3;
    border-radius: 0.4em;
    padding: 2px;
    font-weight: bold;
    font-size: 14px;
}

.container-listo-opcion-selected{
    display: flex;
    gap:5px
}

.subopcion-unica-escogida{
    background-color: #878787;
    border-radius: 5px;
    padding: 2px;
    color:#fcfeff;
    font-size: 15px;
    font-weight: 600;
}

.icono-expand{
    font-size: 28px;
    margin-right: 10px;
    color: #878787;
}

.opciones-ocultas {
    height: 0; /* Establece la altura inicial en 0 */
    overflow: hidden; /* Oculta cualquier contenido desbordante */
    transition: height 2s ease ; /* Agrega una transición suave a la altura */
}



.listo-opcion {
    background-color: #00e53d;
    display: inline;
    border-radius: 5px;
    padding: 2px;
    color: #fcfeff;
    font-weight: 800;
    font-size: 14px;
}
.icono-delete{
    font-size: 21px;
}

.container-opcion-escogida{
    display: flex;
    justify-content: center;
    justify-items: center;
    background-color: #878787;
    border-radius: 5px;
    padding: 2px;
    color:#fcfeff;
    font-size: 15px;
    font-weight: 600;
    gap: 2px;
    text-transform: capitalize;
}



.cant-subopcion{
    font-size: 15px;
    font-weight: bolder;
    margin-top: 9px;
}


.boton-izquierda,
.boton-derecha {
    position: absolute;
    top: 15px;
    font-size: 24px;
    background-color: white;
    border: 0px;
    border-radius: 50%;
    padding: 1px 6px 1px 6px;
}


.boton-izquierda {
    left: 10px;
    /* Ajusta esto según tu diseño */
}

.boton-derecha {
    right: 10px;
    /* Ajusta esto según tu diseño */
}

.div-imagen-prod img {
    width: 100%;
    /* Ajusta el radio de borde según tu preferencia */
}

.div-texto-prod {
    transform: translate(0, -6%);
    background-color: white;
    padding: 15px;
    border-radius: 30px 30px 10px 10px;
    /* Ajusta el radio de borde según tu preferencia */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding-bottom: 20%;
    min-height: 45vh;
    margin-bottom: 15%;
}

.div-texto-prod h1,
.div-texto-prod p {
    margin: 0;
}

.nombre-prod {
    display: flex;
    justify-content: space-between;
    text-transform: capitalize;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    font-size: 30px;
}

.descripcion-producto {
    font-size: 15px !important;
    font-weight: 400 !important;
    letter-spacing: -0.5px;
    color: rgb(119, 119, 119);
    font-family: 'Lato', 'sans-serif';
}

.boton-agregar {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #2aac4d;
    color: white;
    border: none;
    border-radius: 20px;
    font-size: large;
    cursor: pointer;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.7);
}

.opcion-div{
    display: flex;
    justify-content: space-between;
    padding-right: 5px;
    padding-left: 5px;
    border-top: 0.5px solid #b1b0b0;
    margin-bottom: 10px;
    text-transform: capitalize;
    
    
}

.subopcion-nombre{
    font-family: 'Lato', sans-serif;
    color: #696969;
}

.subopcion-precio{
    font-weight: 900;
    font-family: 'Roboto', sans-serif;
}
.icono-check{
    font-size: 25px;
    margin-top: 15px;
}

.div-boton-agregar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    background-color: #fcfeff;
    color: white;
    text-align: center;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.7);
}

.boton-agregar.animado {
    animation: agrandar 0.5s ease-in-out; /* Animación de agrandamiento */
}

@keyframes agrandar {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.25);
    }
    100% {
        transform: scale(1);
    }
}

.precio-prod {
    font-size: 20px !important;
    margin-top: 10px !important;
}

.container-opciones{
    padding: 10px;
    border-radius: 15px;
    background-color: #fffefe;
    margin-bottom: 10px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.17);
}


.opcion-texto {
    display: flex;
    justify-content: space-between;
    border-radius: 10px;    
    padding-top: 5px;
    padding-bottom: 5px;
}

.opcion-titulo {
    font-weight: 900;
    text-transform: capitalize;
    font-size: 20px;

}
.opcion-limite{
    font-weight: 100;
    font-size: 11px;
    color: #777777;
}

.opcion-titulo-limite {
    font-weight: 800;
    font-family: 'Roboto', sans-serif;
    line-height: 10%;
    margin-bottom: 15px;


}

.opcion-descripcion {
    font-size: 11px;
    padding-top: 3px;
    color: #565656;
    text-transform: capitalize;
}










.icon-selected {
    position: absolute;
    right: 20px;
    top: 30%;
    font-size: 20px;
}

.icon-selected-dos {
    position: absolute;
    right: 20px;
    top: 7%;
    font-size: 20px;
}

.opciones-div {
    margin-top: 20px;
    font-family: 'Roboto', sans-serif;

}

/* Extra Small Devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .div-texto-prod {
        padding: 10px;
        min-height: auto;
    }

    .nombre-prod {
        font-size: 24px;
    }

    .descripcion-producto {
        font-size: 14px;
    }

    .boton-agregar {
        font-size: medium;
        padding: 8px 16px;
    }

    .div-imagen-prod img {
        width: 100%;
    }
}

/* Small Devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .div-texto-prod {
        padding: 15px;
    }

    .nombre-prod {
        font-size: 28px;
    }

    .descripcion-producto {
        font-size: 15px;
    }

    .boton-agregar {
        font-size: large;
        padding: 10px 20px;
    }
}

/* Medium Devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .div-texto-prod {
        padding: 20px;
    }

    .nombre-prod {
        font-size: 30px;
    }

    .descripcion-producto {
        font-size: 16px;
    }

    .boton-agregar {
        font-size: large;
        padding: 12px 24px;
    }
}

/* Large Devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .div-texto-prod {
        padding: 25px;
    }

    .nombre-prod {
        font-size: 32px;
    }

    .descripcion-producto {
        font-size: 17px;
    }

    .boton-agregar {
        font-size: large;
        padding: 14px 28px;
    }
}

/* Extra Large Devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .div-texto-prod {
        padding: 30px;
        width: 35%;
        margin: auto;
        margin-bottom: 5%;
    }

    .div-imagen-prod img {
        width: 28%;
        margin: auto;
        border-radius: 10%; /* Centra la imagen */
    }

    .nombre-prod {
        font-size: 34px;
    }

    .descripcion-producto {
        font-size: 18px;
    }

    .boton-agregar {
        font-size: x-large;
        padding: 16px 32px;
    }
}
